import React, { useEffect } from "react";
import _ from "lodash";
import "./CamThumbnail.scss";
import { Link, useHistory } from "react-router-dom";
import liveCam from "../../assets/cams/cam_icon.png";
import playBtn from "../../assets/cams/play_button.svg";
import CamMask from "./CamMask";
import constants from "../../constants";
import Loader from "components/Loader/Loader";

const CamThumbnail = ({ cam }) => {
  const history = useHistory();
  useEffect(() => {}, []);
  const handleClick = () => {
    history.push({
      pathname: `/location/${cam?.slug}`,
    });
  };
  const renderCam = () => {
    return cam?.authorized ? (
      <div className="live-cam-thumbnail">
        <img
          alt={`${cam.name}-thumbnail`}
          className="img rounded img-raised cam-thumbnail-img"
          src={cam?.thumbnails[0]?.url}
          onClick={handleClick}
        ></img>
        <img
          className="cam-thumbnail-icon"
          src={liveCam}
          alt="live_cam"
          style={{ width: "22px", height: "13.5px" }}
        ></img>
        <img
          className="cam-play-button-icon"
          src={playBtn}
          alt="live_cam"
          style={{ width: "40px", height: "39px" }}
          onClick={handleClick}
        ></img>
      </div>
    ) : (
      <div className="live-cam-thumbnail">
        <img
          alt={`${cam.name}-thumbnail`}
          className="img rounded img-raised cam-thumbnail-img"
          src={cam?.thumbnails[0]?.url}
          onClick={handleClick}
        ></img>
        <img
          className="cam-thumbnail-icon"
          src={liveCam}
          alt="live_cam"
          style={{ width: "22px", height: "13.5px" }}
        ></img>
        <CamMask size={constants.MaskSize.SMALL} />
      </div>
    );
  };
  return (
    <div className="cam-thumbnail-container">
      {cam ? renderCam() : <Loader />}
      <h6 className="cam-thumbnail-name">
        <Link to={`/location/${cam.slug}`} className="">
          {cam.name}
        </Link>
      </h6>
    </div>
  );
};

export default CamThumbnail;
