import React, { useEffect, useState } from "react";
import constants from "../../constants";
import "./CamMask.scss";
import { useHistory } from "react-router-dom";
import { getSubscriptionPosition } from "tools/utills";
import { useSelector } from "react-redux";

const CamMask = ({ size }) => {
  const userInfo = useSelector((state) => state?.user?.info);
  const [subscriptionPosition, setSubscriptionPosition] = useState(null);

  useEffect(() => {
    const position = getSubscriptionPosition(userInfo);
    setSubscriptionPosition(position);
  }, [userInfo]);

  const history = useHistory();

  const handleSignUp = () => {
    history.push({ pathname: "/sign-up" });
  };
  const handleSignIn = () => {
    history.push({ pathname: "/sign-in" });
  };
  const handleSubscribe = () => {
    history.push({ pathname: "/packages" });
  };

  const getMessage = () => {
    switch (subscriptionPosition) {
      case constants.SubscriptionPosition.ANONYMOUS:
        return (
          <div
            className="live-cam-mask-text-container"
            style={{
              fontSize: size === constants.MaskSize.SMALL ? "10px" : "18px",
            }}
          >
            <div className="live-cam-mask-text">Members Only Live Surf-Cam</div>
            <div className="live-cam-mask-text">
              <span className="live-cam-mask-links" onClick={handleSignUp}>
                SIGN UP
              </span>{" "}
              or{" "}
              <span className="live-cam-mask-links" onClick={handleSignIn}>
                LOGIN
              </span>
            </div>
          </div>
        );
      case constants.SubscriptionPosition.BLOCKED:
      case constants.SubscriptionPosition.EXPIRED:
      case constants.SubscriptionPosition.UNSUBSCRIBED: {
        return (
          <div
            className="live-cam-mask-text-container"
            style={{
              fontSize: size === constants.MaskSize.SMALL ? "10px" : "18px",
            }}
          >
            <div className="live-cam-mask-text">Members Only Live Surf-Cam</div>
            <div className="live-cam-mask-text">
              <span className="live-cam-mask-links" onClick={handleSubscribe}>
                SUBSCRIBE NOW
              </span>{" "}
            </div>
          </div>
        );
      }
      case constants.SubscriptionPosition.TRIAL:
        return <div />;
      case constants.SubscriptionPosition.MONTHLY:
        return <div />;
      case constants.SubscriptionPosition.YEARLY:
        return <div />;
      default:
        return (
          <div
            className="live-cam-mask-text-container"
            style={{
              fontSize: size === constants.MaskSize.SMALL ? "10px" : "18px",
            }}
          >
            <div className="live-cam-mask-text">Members Only Live Surf-Cam</div>
            <div className="live-cam-mask-text">
              <span className="live-cam-mask-links" onClick={handleSignUp}>
                SIGN UP
              </span>{" "}
              or{" "}
              <span className="live-cam-mask-links" onClick={handleSignIn}>
                LOGIN
              </span>
            </div>
          </div>
        );
    }
  };

  return getMessage();
};

export default CamMask;
